import React from 'react'
import styled from '@emotion/styled'
import {
  plantin,
  plantinBoldItalic,
  gentonaExtraBold,
  plantinItalic,
} from '../../styles'
import titlecase from 'voca/title_case'
import format from 'date-fns/format'

const EventPrice = styled.div`
  font-family: ${gentonaExtraBold};
  margin: 2rem 0;
`

const MenuItems = styled.div`
  @media screen and (min-width: 720px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
  }
`

const MenuItemDescription = styled.p`
  margin: 0.25rem 0;
  font-family: ${plantin};
`

const EventMenuItem = styled.div`
  margin-bottom: 1.25rem;
  text-align: center;
`

const EventDescription = styled.p`
  font-family: ${plantin};
  padding: 0 1rem;
  font-size: 1.8rem;
  line-height: 2.25rem;
  @media screen and (min-width: 720px) {
    padding: 0 2rem;
    margin: 2.5rem 6.5rem;
  }
`
const EventName = styled.h1`
  font-family: ${plantinBoldItalic};
  font-size: 3.8rem;
`

const MenuItemName = styled.h3`
  font-family: ${plantinBoldItalic};
  margin-bottom: 0.25rem;
`

const EventDate = styled.div`
  font-family: ${plantinBoldItalic};
  font-size: 2.4rem;
`

const MenuItemPairing = styled.p`
  margin: 0.25rem 0;
  margin-top: 0.5rem;
  font-family: ${plantinItalic};
`

const EventInfoWrapper = styled.div`
  padding: 0 2rem;
  @media screen and (min-width: 720px) {
    padding: 0;
  }
`

const EventInfoComponent = ({ name, menuItems, date, description, price }) => (
  <EventInfoWrapper>
    <EventName>{name}</EventName>
    <EventDate>
      <div>{format(date, 'dddd, MMMM Do')}</div>
    </EventDate>
    <EventDescription>{description}</EventDescription>
    {!!price && <EventPrice>${price} per guest</EventPrice>}
    <MenuItems>
      {menuItems &&
        menuItems.map(item => (
          <EventMenuItem key={item.name}>
            <MenuItemName>{titlecase(item.name)}</MenuItemName>
            <MenuItemDescription>
              {titlecase(item.description)}
            </MenuItemDescription>
            <MenuItemPairing>{titlecase(item.pairing)}</MenuItemPairing>
          </EventMenuItem>
        ))}
    </MenuItems>
  </EventInfoWrapper>
)

export default EventInfoComponent
