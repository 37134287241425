import React from 'react'
import Select from './Select'
import TextInput from './TextInput'
import TextArea from './TextArea'
import RadioInput from './RadioInput'
import Checkbox from './Checkbox'

const FormElement = props => {
  let component = null
  switch (props.type) {
    case 'text':
      component = <TextInput {...props} />
      break
    case 'tel':
      component = <TextInput {...props} />
      break
    case 'textArea':
      component = <TextArea {...props} />
      break
    case 'select':
      component = <Select {...props} />
      break
    case 'radio':
      component = <RadioInput {...props} />
    case 'checkbox':
      component = <Checkbox {...props} />
  }
  if (!props.validationRules) {
    return component
  }

  return (
    <div>
      {props.validator.message(
        `${props.name}`,
        props.value,
        props.validationRules
      )}
      {component}
    </div>
  )
}

export default FormElement
