import React from 'react'
import { SelectStyles } from '../../styles'
import FormGroup from './FormGroup'
import styled from '@emotion/styled'
const Select = styled.select(
  {
    ...SelectStyles(),
    borderRadius: 0,
    WebkitAppearance: 'none',
  },
  () => ({
    ':not(:last-child)': {},
  })
)

const MagsSelect = ({
  name,
  value,
  stateFunction,
  optionsList,
  label,
  bgColor,
}) => (
  <FormGroup bgColor={bgColor} label={label} name={name}>
    <Select
      bgColor={bgColor}
      value={value}
      name={name}
      onChange={stateFunction}
    >
      {optionsList &&
        optionsList.map(option => (
          <option key={option.name} value={option.value}>
            {option.name}
          </option>
        ))}
    </Select>
  </FormGroup>
)

export default MagsSelect
