import { Component } from 'react'
import Event from '../components/Event'
import Overlay from '../components/Overlay'
import BookForm from '../components/BookForm'
import { getEvents } from '../utils/api'
import PageTitle from '../components/PageTitle'
import { Elements } from 'react-stripe-elements'
import BuildingImage from '../assets/images/svg/mags-building.svg'
import { plantin, buttonStyles, buttonHoverStyles, darkGreen } from '../styles'
import Layout from '../components/layout'
import styled from '@emotion/styled'

const NewsLetterButton = styled.a`
  ${buttonStyles};
  ${buttonHoverStyles};
  display: block;
  text-align: center;
  color: #fff;
  background-color: ${darkGreen};
  &:hover {
    background-color: transparent;
    color: ${darkGreen};
  }
`
const EventPageWrapper = styled.div({
  position: 'relative',
  minHeight: '90vh',
  transition: 'opacity 200ms ease-in-out',
  opacity: 1,
  '&.loading': {
    opacity: 0,
  },
})

const EventsWrapper = styled.div({
  margin: '0 auto',
  padding: '1rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1200px))',
  '@media screen and (min-width: 720px)': {
    paddingTop: '2rem',
    maxWidth: '70%',
  },
})

const ImageWrapper = styled.div({
  marginBottom: '3rem',
  maxWidth: '100%',
  padding: '0 2rem',
  minWidth: '50%',
  textAlign: 'center',
  '@media screen and (min-width: 720px)': {
    maxWidth: '50%',
  },
})

const NoEventsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '80vh',
  fontFamily: plantin,
})

class SpecialEventsPage extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      selectedEvent: {},
      bookEvent: false,
      events: [],
    }
  }

  selectEvent = event => {
    this.setState({ selectedEvent: event, bookEvent: true })
  }

  closeOverlay = () => {
    this.setState({ ...this.state, bookEvent: false })
  }

  componentDidMount = () => {
    this.setState({ ...this.state, loading: true }, () => {
      getEvents()
        .then(events => this.setState({ ...this.state, events: events }))
        .then(() => {
          this.setState({ ...this.state, loading: false })
        })
    })
  }

  render() {
    const events = this.state.events
    return (
      <Layout>
        <EventPageWrapper className={this.state.loading ? 'loading' : ''}>
          <PageTitle header="Our Special Events" />
          <EventsWrapper>
            {!!events.length &&
              events.map(event => (
                <Event
                  {...event}
                  handleEventSelect={event => this.selectEvent(event)}
                />
              ))}
            {!events.length && (
              <NoEventsWrapper>
                <ImageWrapper>
                  <BuildingImage />
                  <p>We don’t have any events scheduled scheduled right now.</p>
                  <p>
                    But sign up for our newsletter to learn about our events as
                    soon as we add them to the calendar.
                  </p>
                  <NewsLetterButton
                    target="_blank"
                    rel="noopener"
                    href="https://visitor.r20.constantcontact.com/d.jsp?llr=wyxfzfcab&p=oi&m=1101853286166&sit=phcypgxcb&f=249bc362-12b7-4d74-bb7b-d6551ceba038"
                  >
                    Sign Up For Our Newsletter
                  </NewsLetterButton>
                </ImageWrapper>
              </NoEventsWrapper>
            )}
          </EventsWrapper>

          <Overlay
            showMe={this.state.bookEvent}
            closeScreen={this.closeOverlay}
          >
            <Elements>
              <BookForm event={this.state.selectedEvent} />
            </Elements>
          </Overlay>
        </EventPageWrapper>
      </Layout>
    )
  }
}

export default SpecialEventsPage
