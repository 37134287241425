import React from 'react'
import { SelectStyles } from '../../styles'
import FormGroup from './FormGroup'
import styled from '@emotion/styled'

const RadioInput = styled.input({})
const RadioGroup = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Label = styled.span({
  paddingLeft: '1rem',
})
const RadioInputComponent = ({ optionsList }) => (
  <RadioGroup>
    {optionsList.map(option => (
      <label key={option.value}>
        <RadioInput type="radio" name={option.name} value={option.value} />
        <Label>{option.label}</Label>
      </label>
    ))}
  </RadioGroup>
)

export default RadioInputComponent
