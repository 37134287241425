import React from 'react'
import FormGroup from './FormGroup'

const StripeElement = (props) => {
  return (
    <FormGroup {...props}>
      {props.children}
    </FormGroup>

  )
}

export default StripeElement
