import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  plantinBoldItalic,
  buttonHoverStyles,
  cream,
  buttonStyles,
  plantinItalic,
  errorColor,
} from '../../styles'
import { Link } from 'gatsby'
import format from 'date-fns/format'

const Spinner = styled.div(
  {
    visibility: 'hidden',
    position: 'fixed',
    width: '100vw',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: -1,
    padding: '1rem',
    backgroundColor: cream,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    opacity: '0',
    height: 0,
    transition: 'visibility 0s linear 300ms, opacity 300ms',
    '&.active': {
      zIndex: 1000,
      height: 'auto',
      visibility: 'visible',
      transition: 'visibility 0s linear 0s, opacity 300ms',
      opacity: 1,
    },
  },
  () => ({})
)
const SpinnerText = styled.span({
  fontSize: '4rem',
  // visibility: 'hidden',
  fontFamily: plantinBoldItalic,
  // opacity: '0',

  '&.show': {
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility 0s linear 0s, opacity 300ms',
  },
  transition: 'visibility 0s linear 100ms, opacity 100ms',
})

const SpinnerTextWrapper = styled.h1({
  position: 'relative',
  maxWidth: '780px',
})

const Message = styled.p(
  {
    fontFamily: plantinItalic,
    fontSize: '2.4rem',
    '&.error': {
      color: errorColor,
    },
  },
  () => ({})
)

const SuccessMessage = styled.div({})

const ErrorMessage = styled.div({})

const ThankYouButton = css`
  ${buttonHoverStyles}
  ${buttonStyles}
  text-decoration: none;
  padding: 2rem;
`

const ReturnLink = styled.div(
  {
    fontSize: '1.8rem',
    // ...buttonStyles
  },
  () => ({
    // ...buttonHoverStyles
  })
)

const renderMessage = (success, name, date, error) => {
  if (success) {
    return (
      <SuccessMessage>
        <SpinnerText>
          <div>Booking Success!</div>
        </SpinnerText>
        <Message>
          We look forward to seeing you for the {name} on{' '}
          <div>{format(date, 'dddd, MMMM Do')}.</div>
        </Message>
        <ReturnLink>
          <Link css={ThankYouButton} to="/">
            Return to our home page
          </Link>
        </ReturnLink>
      </SuccessMessage>
    )
  } else if (error) {
    return (
      <ErrorMessage>
        <SpinnerText>
          Oh, no! Something went wrong with your payment!{' '}
        </SpinnerText>
        <Message className="error">{error}</Message>
        <Message>Please try again or give us a call at 540-338-9800</Message>
        <ReturnLink>
          <Link css={ThankYouButton} to="/">
            Return to our home page
          </Link>
        </ReturnLink>
      </ErrorMessage>
    )
  }
  return <SpinnerText>One moment, please&hellip;</SpinnerText>
}

const SpinnerComponent = ({ isActive, success, error, name, date }) => {
  return (
    <Spinner className={isActive ? 'active' : null}>
      <div>
        <SpinnerTextWrapper>
          {renderMessage(success, name, date, error)}
        </SpinnerTextWrapper>
      </div>
    </Spinner>
  )
}

export default SpinnerComponent
