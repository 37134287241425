import GuestCount from './GuestCount'
export default [
  {
    type: 'text',
    name: 'name',
    placeholder: null,
    label: 'Your Name',
    required: true,
    validationRules: 'required|min:2|max:120'
  },
  {
    type: 'text',
    name: 'email',
    placeholder: null,
    label: 'Your Email Address',
    required: true,
    validationRules: 'required|email'
  },
  {
    type: 'tel',
    name: 'phone',
    placeholder: null,
    label: 'Your Phone Number',
    required: true,
    validationRules: 'required|phone'
  },
  {
    type: 'select',
    name: 'guests',
    placeholder: null,
    label: 'Number in Your Party',
    required: true,
    validationRules: null,
    optionsList: GuestCount
  },
  {
    type: 'textArea',
    name: 'requests',
    placeholder: 'Food allergies or seating requests, for example',
    label: 'Any Special Requests',
    required: false,
    validationRules: null
  }
]
