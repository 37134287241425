import { Component } from 'react'
import { postEventGuest } from '../../utils/api'
import Spinner from '../Spinner'
import {
  buttonStyles,
  buttonHoverStyles,
  errorColor,
  textColor,
  gentonaExtraBold,
} from '../../styles'
import SimpleReactValidator from 'simple-react-validator'
import bookingFormItems from './bookingFormItems'
import FormInput from '../Forms'
import EventInfo from './EventInfo'
import StripeElement from '../Forms/StripeElement'
import { injectStripe, CardElement } from 'react-stripe-elements'
import styled from '@emotion/styled'

const BookFormWrapper = styled.form`
  margin: 0 auto;
  @media screen and (min-width: 720px) {
    padding: 1rem 2rem;
    maxwidth: 50%;
  }
`

const BookForm = styled.div`
  margin-top: 2rem;
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  @media screen and (min-width: 720px) {
    padding: 2rem 2rem 3rem;
    width: 420px;
  }
`
const BookButton = styled.button`
  ${buttonStyles}
  ${buttonHoverStyles}
`

const TotalPrice = styled.div`
  font-size: 2rem;
  padding-bottom: 1.5rem;
  font-family: ${gentonaExtraBold};
`

const ErrorMessage = styled.div`
  color: ${errorColor};
  padding: 1rem 0;
`

class BookFormComponent extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator()
    this.state = {
      event: {
        name: '',
        eventId: null,
        totalPrice: 0,
        email: '',
        phone: '',
        requests: '',
        guests: 1,
      },
      stripeError: null,
      loading: false,
      bookingSuccess: '',
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    const { createToken, card } = this.props.stripe
    const { name, email, phone, guests, requests } = this.state.event
    const { id } = this.props.event
    if (!this.validator.allValid()) {
      this.validator.showMessages()
      this.forceUpdate()
      return false
    }
    this.setState({ ...this.state, submitDisabled: true }, () => {
      createToken(card).then(result => {
        if (result.error) {
          this.setState({
            ...this.state,
            stripeError: result.error.message,
          })
        } else {
          this.setState(
            { ...this.state, loading: true, stripeError: null },
            () => {
              postEventGuest({
                name,
                email,
                phone,
                eventId: id,
                guests,
                requests,
                stripeToken: result.token.id,
              })
                .then(res =>
                  this.setState({
                    ...this.state,
                    submitDisabled: false,
                    bookingSuccess: true,
                    stripeError: null,
                  })
                )
                .catch(error => {
                  const errorMessage = error.response.data.message
                  this.setState({
                    ...this.state,
                    submitDisabled: false,
                    stripeError: errorMessage,
                    bookingSuccess: false,
                  })
                })
                .finally(() => {})
            }
          )
        }
      })
    })
  }
  componentWillReceiveProps = () => {
    this.setState({
      ...this.state,
      loading: false,
      event: { ...this.state.event, guests: 1 },
      stripe: { ...this.state.stripe, error: null },
    })
  }

  updateEventState = event => {
    const { name, value } = event.target
    this.setState({
      ...this.state,
      event: {
        ...this.state.event,
        [name]: value,
      },
    })
  }

  render() {
    const { name, price, date } = this.props.event
    const { guests } = this.state.event
    const { stripeError, bookingSuccess, loading } = this.state
    return (
      <BookFormWrapper>
        <EventInfo {...this.props.event} />
        {!!price && (
          <div>
            <BookForm onSubmit={e => e.preventDefault()}>
              {bookingFormItems.map(item => (
                <FormInput
                  {...item}
                  key={item.name}
                  validator={this.validator}
                  value={this.state.event[item.name]}
                  stateFunction={this.updateEventState}
                />
              ))}

              <StripeElement label="Credit Card Information" required>
                <CardElement
                  style={{ base: { color: textColor, fontSize: '16px' } }}
                />
              </StripeElement>
              {stripeError && <ErrorMessage>{stripeError}</ErrorMessage>}
              <TotalPrice>Total: ${price * guests}</TotalPrice>
              <BookButton
                onClick={this.handleSubmit}
                disabled={this.state.submitDisabled}
              >
                Submit
              </BookButton>
            </BookForm>
          </div>
        )}
        <Spinner
          name={name}
          date={date}
          isActive={loading}
          success={bookingSuccess}
          error={stripeError}
        />
      </BookFormWrapper>
    )
  }
}
export default injectStripe(BookFormComponent)
