import { css } from '@emotion/react'
import styled from '@emotion/styled'
import format from 'date-fns/format'
import React from 'react'
import BuildingImage from '../../assets/images/svg/mags-building.svg'
import {
  buttonStyles,
  darkBlue,
  gentonaExtraBold,
  plantin,
  plantinBoldCondensed,
  plantinBoldItalic,
  red,
  textColor,
  yellow,
  gentonaExtraLight,
} from '../../styles'

const EventWrapper = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border: 2px solid ${textColor};
  padding: 1rem;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr;
  font-size: 1.6rem;
  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    border: none;
    border-bottom: 2px solid ${textColor};
    text-align: left;
  }
`

const EventHeader = styled.h2`
  margin: 1rem 0;
  font-family: ${plantinBoldItalic};
  @media screen and (min-width: 720px) {
    margin: 0;
  }
`

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 2rem;
  @media screen and (min-width: 1200px) {
    margin: 0;
  }
`
const EventPrice = styled.div`
  font-family: ${gentonaExtraBold};
  padding-right: 1rem;
`

const EventDescription = styled.p`
  font-family: ${plantin};
  text-align: left;
  margin: 0.75rem 0;
`

const EventDate = styled.div`
  font-family: ${plantinBoldCondensed};
  font-size: 1.8rem;
`

const EventMenuLink = styled.div`
  font-family: ${gentonaExtraLight};
`
const EventBookButton = css`
  ${buttonStyles}
  &:hover {
    background-color: ${darkBlue};
    color: ${yellow};
  }
`

const DateAndTime = styled.div``
const PriceAndMenu = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-around;
  @media screen and (min-width: 720px) {
    margin-bottom: 0;
    justify-content: center;
  }
`

const EventTimeAndDate = styled.div``

const BookLinkStyles = css`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
`

const ImageContainer = styled.div`
  img {
    display: block;
  }
`

const RenderBookButtons = ({
  soldOut,
  isExternal,
  price,
  handleEventSelect,
  externalURL,
  name,
  description,
  date,
  id,
  menuItems,
}) => {
  if (soldOut) {
    return (
      <button
        css={BookLinkStyles}
        css={EventBookButton}
        disabled
        style={{ backgroundColor: red }}
      >
        Sold Out!
      </button>
    )
  } else if (!soldOut && isExternal) {
    return (
      <a
        href={externalURL}
        target="_blank"
        rel="noopener"
        className="external-link"
        css={BookLinkStyles}
        css={EventBookButton}
      >
        Book on EventBrite
      </a>
    )
  } else if (!price && !externalURL && !soldOut) {
    return (
      <button
        css={BookLinkStyles}
        css={EventBookButton}
        onClick={() =>
          handleEventSelect({ name, price, description, date, id, menuItems })
        }
      >
        View Details
      </button>
    )
  } else {
    return (
      <button
        css={BookLinkStyles}
        css={EventBookButton}
        onClick={() =>
          handleEventSelect({
            name,
            price,
            description,
            date,
            id,
            menuItems,
          })
        }
      >
        Book
      </button>
    )
  }
}

const DescriptionPriceAndMenu = styled.div({})
const Event = props => {
  const {
    date,
    time,
    price,
    description,
    menuFilename,
    imageFilename,
    name,
  } = props
  return (
    <EventWrapper>
      <EventDetails>
        <DescriptionPriceAndMenu>
          <EventHeader>{name}</EventHeader>
          <DateAndTime>
            <EventTimeAndDate>
              <EventDate>
                <div>
                  <span>{format(date, 'MMMM Do')}</span>
                  <span> {time}</span>
                </div>
              </EventDate>
            </EventTimeAndDate>
          </DateAndTime>
          <EventDescription>{description}</EventDescription>
          <PriceAndMenu>
            {!!price && <EventPrice>${price}</EventPrice>}
            {menuFilename && (
              <EventMenuLink>
                <a
                  href={`https://api.magnoliasmill.com/menus/${menuFilename}`}
                  target="_blank"
                >
                  Menu (PDF)
                </a>
              </EventMenuLink>
            )}
          </PriceAndMenu>
        </DescriptionPriceAndMenu>
        <div>{RenderBookButtons(props)}</div>
      </EventDetails>
      <ImageContainer>
        {imageFilename ? (
          <img
            style={{ display: 'block' }}
            src={`https://api.magnoliasmill.com/menus/${imageFilename}`}
          />
        ) : (
          <BuildingImage />
        )}
      </ImageContainer>
    </EventWrapper>
  )
}

export default Event
