import React from 'react'
import styled from '@emotion/styled'
import { textColor, errorColor, plantin } from '../../styles'
import FormGroup from './FormGroup'

const Input = styled.input({
  //   background Color: 'transparent',
  width: '25px',
  //   border: `2px solid ${textColor}`,
  //   padding: '1.5rem 1rem',
  //   WebkitAppearance: 'none',
  //   outline: 'none',
  height: '25px',
  borderRadius: 0,
  color: textColor,
  transform: 'translateZ(0px)', // Fix iOS disappearing text issue
  fontFamily: plantin,
  transition: 'border 100ms linear',
  zIndex: 0,
  ':not(:last-child)': {
    marginBottom: '.5rem',
  },
  '.invalid': {
    border: `2px solid ${errorColor}`,
  },
})

const Label = styled.label`
  display: flex;
  align-items: center
`

const MagsInputCheckbox = ({
  label,
  placeholder,
  name,
  value,
  type,
  stateFunction,
  bgColor,
  style,
  required,
}) => (
  <Label htmlFor={name}>
    <span>{label}</span>
    <Input
      type={type}
      placeholder={placeholder}
      value={value}
      name={name}
      style={style}
      onChange={stateFunction}
    />
  </Label>
)

export default MagsInputCheckbox
